<template>
    <div>
        <!-- 顶部工具条 -->
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label='关键字:'>
                        <el-input v-model='filters.condition' placeholder='考生姓名/手机' clearable @clear='clearContent'></el-input>
                    </el-form-item>
                    <el-form-item label="审核状态:">
                        <el-select v-model="filters.ReviewStatus" clearable>
                            <el-option
                                v-for="item in ReviewStatusArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>                           
            </el-col>         
        </el-row>
        <!-- 表格主体 -->
        <el-table
            v-loading='listLoading'
            :data="tableData"
            border
            style="width: 100%"
            highlight-current-row
            ref="multipleTable"
            @current-change='currentChange'
            :cell-style='rowClass'
            :header-cell-style='headerStyle'
        >   
            <el-table-column type="selection" :selectable="isDisabled" width="45"></el-table-column>
            <el-table-column prop="Name" label="姓名"></el-table-column>
            <el-table-column prop="Gender" label="性别" min-width="100">
                <template slot-scope='scope'>
                    <el-tag type='' v-if='scope.row.Gender == 1'>男</el-tag>
                    <el-tag type='success' v-else>女</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="SocialUnitName" label="社会单位名称" min-width="140"></el-table-column>
            <el-table-column prop="PhoneNumber" label="手机号" min-width="100"></el-table-column>
            <el-table-column prop="CardNo" label="证件号" min-width="150"></el-table-column>
            <el-table-column prop='CardPhotoUrl' label='证件照片' width='100'>
                <template slot-scope='scope'>
                    <el-popover placement='right' trigger='hover'>
                        <img :src="scope.row.CardPhotoUrl" alt="" style='width: auto;height: auto;max-width:120px;max-height:150px'> 
                        <img slot="reference" :src="scope.row.CardPhotoUrl" @click="viewBigPhoto(scope.row)" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;'>                   
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column prop='CardAndHeadPhotoUrl' label='手持个人证件照片' width='100'>
                <template slot-scope='scope'>
                    <el-popover placement='right' trigger='hover'>
                        <img :src="scope.row.CardAndHeadPhotoUrl" alt="" style='width: auto;height: auto;max-width:120px;max-height:150px'> 
                        <img slot="reference" :src="scope.row.CardAndHeadPhotoUrl" @click="viewBigPhoto(scope.row)" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;'>                   
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column prop='HeadPhotoUrl' label='人像照' width='100'>
                <template slot-scope='scope'>
                    <el-popover placement='right' trigger='hover'>
                        <img :src="scope.row.HeadPhotoUrl" alt="" style='width: auto;height: auto;max-width:120px;max-height:150px'> 
                        <img slot="reference" :src="scope.row.HeadPhotoUrl" @click="viewBigPhoto(scope.row)" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;'>                   
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column prop="ReviewStatus" label="审核状态" min-width="100">
                <template slot-scope='scope'>
                    <el-tag type='' v-if='scope.row.ReviewStatus == 0'>待审核</el-tag>
                    <el-tag type='success' v-if='scope.row.ReviewStatus == 1'>审核通过</el-tag>
                    <el-tag type='danger' v-if='scope.row.ReviewStatus == 2'>审核驳回</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="Remarks" label="驳回原因" min-width="100">
                <template slot-scope='scope'>
                    <span type='danger' v-if='scope.row.ReviewStatus == 2'>{{scope.row.Remarks}}</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.PageIndex"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pages.PageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.DataCount"
                class='pageBar'
            >
        </el-pagination>
        <el-dialog
            title="查看图片"
            :visible.sync="viewPhotoDialog"
            v-model="viewPhotoDialog"
            :close-on-click-modal='true'
        >
            
            <div>
                <div style="text-align: center;">
                    <div style="text-align: left;margin: 20px;font-size: 18px;font-weight: bold;">证件照片：</div>
                    <img :src="photoObj.CardPhotoUrl" alt="" style='width: auto;height: auto;max-width:500px;max-height:680px;'>
                </div>
                <div style="text-align: center;">
                    <div style="text-align: left;margin: 20px;font-size: 18px;font-weight: bold;">手持个人证件照片：</div>
                    <img :src="photoObj.CardAndHeadPhotoUrl" alt="" style='width: auto;height: auto;max-width:500px;max-height:680px;'>
                </div>
                <div style="text-align: center;">
                    <div style="text-align: left;margin: 20px;font-size: 18px;font-weight: bold;">人像照：</div>
                    <img :src="photoObj.HeadPhotoUrl" alt="" style='width: auto;height: auto;max-width:500px;max-height:680px;'>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="viewPhotoDialog = false">关闭</el-button>
            </div>
        </el-dialog>
        <!-- 审核驳回 -->
        <el-dialog
            title="驳回原因"
            :visible.sync="auditRejectionDialog"
            v-model="auditRejectionDialog"
            :close-on-click-modal='true'
        >
            <el-form :model="auditRejectionForm" label-width="100px" :rules="auditRejectionFormRules" ref="auditRejectionRef">
                <el-form-item label="驳回原因：" prop="auditStr">
                    <el-input type="textarea" :rows="4" v-model="auditRejectionForm.auditStr" placeholder="请输入驳回原因"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="auditRejectionDialog = false">取消</el-button>
                <el-button type="primary" @click.native="auditRejectionSubmit" :loading="auditRejectionLoading">提交</el-button>
            </div>
        </el-dialog>
  </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from 'qs';
import util from "../../../util/date";
import {getStudentApprovePageList,reviewStudentApprove,updateStudentApprove,deleteStudentApprove} from '@/api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            filters:{
                condition: '',
                ReviewStatus: ""
            },
            ReviewStatusArr: [
                {name: "待审核", value: 0},
                {name: "审核通过", value: 1},
                {name: "审核驳回", value: 2},
            ],
            tableData:[],
            currentRow: "",
            pages:{
                PageIndex:1,
                PageSize:20,
                DataCount:0
            },
            status:null,
            buttonList:[],
            listLoading:false,
            // 查看图片
            viewPhotoDialog: false,
            photoObj: "",
            // 审核驳回
            auditRejectionDialog: false,
            auditRejectionLoading: false,
            auditRejectionForm: {
                auditStr: "",
            },
            auditRejectionFormRules: {
                auditStr: [{ required: true, message: "请输入驳回原因", trigger: "blur" }],
            },
        }
    },
    methods:{
        // 列表样式
        rowClass(){
            return 'text-align:center;'
        },
        headerStyle(){
            return 'text-align:center;'
        },
        // 筛选框清空重新请求数据
        clearContent(){
            this.getData()
        },
        // 查看大图
        viewBigPhoto(val) {
            this.photoObj = val
            this.viewPhotoDialog = true
        },
        // 页面数据
        getData(){
            this.listLoading = true
            var params = {
                keyWord:this.filters.condition,
                reviewStatus:this.filters.ReviewStatus,
                pageIndex:this.pages.PageIndex,
                pageSize:this.pages.PageSize
            }
            getStudentApprovePageList(params).then(res => {
                if(res.data.Success){
                    this.listLoading = false
                    this.tableData = res.data.Response.Data
                    this.pages.DataCount = res.data.Response.DataCount
                    this.currentRow = ""
                }else{
                    this.listLoading = false
                    this.$message.error(res.data.Message)
                }
            })
        },
        // 获取当前行数据
        currentChange(row){
            this.currentRow = row
        },
        // 分页
        handleSizeChange(value){
            this.pages.PageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.PageIndex = value
            this.getData()
        },
        // 查询事件
        getTableDataFun(){
            this.pages.PageIndex = 1
            this.getData()
        },
        // 审核通过
        passExamination() {
            var selectionData = this.$refs.multipleTable.selection
            if(selectionData == 0) {
                this.$message.warning("请至少多选一条数据！")
                return
            }
            // if(!this.currentRow) {
            //     this.$message.warning("请单选一行数据！")
            //     return
            // }
            this.$confirm('确定要审核通过?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(res => {
                var ids = []
                selectionData.forEach(item => {
                    ids.push(item.Id)
                });
                var params = {
                    ids: ids.join(","),
                }
                reviewStudentApprove(params).then(res => {
                    if(res.data.Success){
                        this.$message({
                            message: res.data.Message,
                            type: 'success'
                        });                    
                        this.getData()
                    }else{
                        this.$message({
                            message: res.data.Message,
                            type: 'error'
                        });
                    }
                })
            }).catch(() => {})   
        },
        // 审核驳回
        rejectExamination() {
            // var selectionData = this.$refs.multipleTable.selection
            // if(selectionData == 0) {
            //     this.$message.warning("请至少多选一条数据！")
            //     return
            // }
            if(!this.currentRow) {
                this.$message.warning("请单选一行要驳回的数据！")
                return
            }
            if(this.currentRow.ReviewStatus == 1 || this.currentRow.ReviewStatus == 2) {
                this.$message.warning("审核通过/审核驳回数据不可审核驳回！")
                return
            }
            this.auditRejectionDialog = true
            this.auditRejectionLoading = false
            this.auditRejectionForm = {
                auditStr: "",
            }
        },
        // 审核驳回提交
        auditRejectionSubmit() {
            this.$refs.auditRejectionRef.validate((valid) => {
                if (valid) {
                    this.$confirm('确定要审核驳回?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        closeOnClickModal:false
                    }).then(res => {
                        var params = {
                            Id: this.currentRow.Id,
                            Remarks: this.auditRejectionForm.auditStr,
                        }
                        updateStudentApprove(params).then(res => {
                            if(res.data.Success){
                                this.$message({
                                    message: res.data.Message,
                                    type: 'success'
                                });
                                this.auditRejectionDialog = false
                                this.getData()
                            }else{
                                this.$message({
                                    message: res.data.Message,
                                    type: 'error'
                                });
                            }
                        })
                    }).catch(() => {})
                }
            })
        },
        // 删除
        handleDel(){
            var selectionData = this.$refs.multipleTable.selection
            if(selectionData == 0) {
                this.$message.warning("请至少多选一条数据！")
                return
            }
            this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(res => {
                var ids = []
                selectionData.forEach(item => {
                    ids.push(item.Id)
                });
                var params = {
                    ids: ids.join(","),
                }
                deleteStudentApprove(params).then(res => {
                    if(res.data.Success){
                        this.$message({
                            message: res.data.Message,
                            type: 'success'
                        });                    
                        this.getData()
                    }else{
                        this.$message({
                            message: res.data.Message,
                            type: 'error'
                        });
                    }
                })
            }).catch(() => {})           
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 复选框禁用选择
        isDisabled(row,rowIndex) {
            if(row.ReviewStatus == 1 || row.ReviewStatus == 2) {
                return false
            } else {
                return true
            }
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.PageIndex
            var pageSize = this.pages.PageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
        // 时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        },
    },
    created(){
        this.getData()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
     .pageBar{
			 margin-top :15px;
         height 70px;
     }
</style>